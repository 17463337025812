import React, { FC } from 'react';
import { createRoot } from 'react-dom/client';
import { MainPage } from 'pages/main';
import { MockPage } from 'pages/mock';
import { SuccessPage } from 'pages/success';
import { FailPage } from 'pages/fail';

import './style.scss'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';


const router = createBrowserRouter([
  {
    path: 'i/:roomId',
    element: <MainPage/>,
  },
  {
    path: 'success',
    element: <SuccessPage/>,
  },
  {
    path: 'fail',
    element: <FailPage/>,
  },
  {
    path: '*',
    element: <MockPage/>,
  }
]);

const App: FC = () => {
  return <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
}

const root = createRoot(document.getElementById('root')!);
root.render(<App/>);
