import { FC, PropsWithChildren } from 'react';
import s from './center-layout.module.scss';


export const CenterLayout: FC<PropsWithChildren> = ({ children }) => {
  return <div className={s.root}>
    <div className={s.center}>
      { children }
    </div>
  </div>
}