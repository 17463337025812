import { FC } from 'react';
import classNames from 'classnames';
import { ButtonProps } from './types';
import { detectTypeByAttrs } from './Button.helpers';
import s from './button.module.scss';

export const Button: FC<ButtonProps> = ({ children, ...root }) => {
  const type = detectTypeByAttrs(root);
  const attrs = {
    ...root,
    tabIndex: root.tabIndex ?? (root.disabled ? -1 : undefined),
    className: classNames(s.root, root?.className, {
      [s.disabled]: root.disabled,
      [s.xs]: root.size === 'xs',
      [s.md]: root.size === 'md'
    })
  };

  if (type === 'link') {
    return <a {...(attrs as any)}>{children}</a>;
  }

  return <button {...(attrs as any)}>{children}</button>;
};
