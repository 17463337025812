import React, { FC, useState } from 'react';
import { CardBox } from 'components/CardBox';
import { ProfileProps } from './types';
import defaultUserPicUrl from './avatar.svg';

import s from './profile.module.scss';

export const Profile: FC<ProfileProps> = (props) => {
  const [isPicLoadFail, setIsPicLoadFail] = useState(false);
  const shouldShowOrigUserPic = !!(props.avatarUrl && !isPicLoadFail);

  return (<div className={s.root}>
    <div className={s.avatar}>
      {shouldShowOrigUserPic && <img
        className={s.avatarImage}
        src={props.avatarUrl}
        alt="user pic"
        draggable={false}
        onError={() => setIsPicLoadFail(true)}
      />}
      {!shouldShowOrigUserPic && <img
        className={s.avatarImage}
        src={defaultUserPicUrl as any}
        alt="user pic"
        draggable={false}
        onError={() => setIsPicLoadFail(true)}
      />}
    </div>
    <CardBox>
      <h1 className={s.title}>{props.sessionName}</h1>
      <p className={s.text}>We allow to download all images or part of it</p>
      { props.downloadOptionsElem }
    </CardBox>
  </div>)
};