import { RadioItem } from 'components/RadioGroup';
import { RoomStats } from '../types';
import { useState } from 'react';
import capitalize from 'lodash/capitalize';


export const buildStatsDownloadOptionsBuilder = (actionLabel: string, additionalInfo: string, hasSinglePhotoMode = true) => {
  return (stats: RoomStats): RadioItem[] => {
    return [
      ...Object
      .entries(stats)
      .filter(([_, num]) => num > 0)
      .map(([value, num]) => [value, `${num}`])
      .map(([value, secondaryInfo]) => ({
        primaryInfo: capitalize(value),
        value,
        secondaryInfo,
        actionLabel,
        additionalInfo,
      })),
      ...(hasSinglePhotoMode ? [{
        primaryInfo: 'Selected',
        value: 'selected',
        secondaryInfo: '',
        actionLabel,
        additionalInfo,
      }] : [])
    ];
  }
}

export const useCommonState = () => {
  const [state, setState] = useState({
    activeDownloadOption: null as any as string,
    selectedPreviews: [] as string[]
  });

  return [
    {
      ...state,
      canSelect: state.activeDownloadOption === 'selected'
    },
    {
      toggleSelected: (previewId: string) => setState(state => ({
        ...state,
        selectedPreviews: state.selectedPreviews.includes(previewId)
          ? []
          : [previewId]
      })),
      setActiveDownloadOption: (activeDownloadOption: string) => setState(state => ({
        ...state,
        selectedPreviews: [],
        activeDownloadOption
      })),
    }
  ] as const;
}

export const buildSessionName = (userName: string) => userName ?? 'Session'
