import { FC } from 'react';
import rcn from 'classnames';
import { GalleryProps } from './types';
import { ReactComponent as Checkbox } from 'icons/checkbox.svg';
import { ReactComponent as CheckboxSelected } from 'icons/checkbox-selected.svg';

import s from './gallery.module.scss';

export const Gallery: FC<GalleryProps> = (props) => {
  const { canSelect, previews, selectedPreviews, toggleSelected } = props;
  const selectedPreviewsMap = selectedPreviews
    .reduce((acc, id) => ({
      ...acc, [id]: true }), {} as Record<string, boolean | void>);

  return (<div className={rcn(s.root, {[s.canSelect]: canSelect})}>
    {previews.map(({ _id, preview: id, preview: url }: any) => {
      const securedId = _id ?? id;
      return <label
        className={rcn(s.item, { [s.selected]: selectedPreviewsMap[securedId]})}
        key={securedId}
        onContextMenu={e => e.preventDefault()}
      >
        <img
          className={s.image}
          src={url}
          alt={url}
          draggable={false}
        />
        {canSelect && <>
          <input
              className={s.checkbox}
              type="checkbox"
              checked={!!selectedPreviewsMap[securedId]}
              onChange={() => toggleSelected(securedId)}
          />
          {!selectedPreviewsMap[securedId] && <Checkbox className={s.checkboxLabel} />}
          {selectedPreviewsMap[securedId] && <CheckboxSelected className={s.checkboxLabel} />}
        </>}
      </label>
    })}
  </div>)
};