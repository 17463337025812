import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { config } from 'config';
import { useHttpState } from 'utils/hooks/httpState.hook';
import { isForPaymentRoom, isFreeRoom, OpenRoomResponse } from 'api/types';
import { CenterLayout } from 'modules/center-layout';
import { Button } from 'components/Button';

import { Loader } from 'components/Loader';
import s from './main-page-container.module.scss';
import { FreeMainPage } from './FreeMainPage';
import { ForPaymentMainPage } from './ForPaymentMainPage';
import { PaidMainPage } from './PaidMainPage';

export const MainPageWrapper = () => {
  const params = useParams<{ roomId: string }>();
  const [isReloading, setIsReloading] = useState<boolean>();
  const request = useHttpState<OpenRoomResponse>(`${config.REACT_APP_API_BASE_URL}/public/room/${params.roomId}`);
  const reloadFn = () => {
    setIsReloading(true);
    window.location.reload()
  };

  const buildUnsuccessfulTemplate = (title: string, actionLabel: string) => (<CenterLayout>
    <div className={s.center}>
      <h1>{title}</h1>
      <Button className={s.errBtn} onClick={reloadFn}>{actionLabel}</Button>
    </div>
  </CenterLayout>);

  if (request.pending || isReloading) {
    return <Loader position='center'/>
  }

  if (request.failed || !request.result) {
    return buildUnsuccessfulTemplate('Something went wrong!', 'Reload');
  }

  if (request.result.gallery_type !== 'paid' && (request.result.all?.count ?? 0) < 1) {
    return buildUnsuccessfulTemplate('There are no photos in this session yet', 'Update');
  }

  if (isFreeRoom(request.result)) {
    return <FreeMainPage {...request.result}/>;
  }

  if (isForPaymentRoom(request.result)) {
    return <ForPaymentMainPage {...request.result} roomId={params.roomId!}/>;
  }


  return <PaidMainPage {...request.result} roomId={params.roomId!}/>;
}