import React, { FC } from 'react';
import { FreeOpenRoomResponse } from 'api/types';
import { Button } from 'components/Button';
import { RadioGroup } from 'components/RadioGroup';
import { buildSessionName, useCommonState, buildStatsDownloadOptionsBuilder } from './hooks/common';
import { Gallery } from './Gallery';
import { Profile } from './Profile';

import s from './main-page.module.scss';

export type FreeMainPageProps = FreeOpenRoomResponse;

const buildLabels = buildStatsDownloadOptionsBuilder(
  'Download',
  'After you click download starts'
);

export const FreeMainPage: FC<FreeMainPageProps> = (props) => {
  const [
    { selectedPreviews, canSelect, activeDownloadOption },
    { toggleSelected, setActiveDownloadOption }
  ] = useCommonState();

  const downloadOptions = <RadioGroup
    value={activeDownloadOption}
    onChange={(e) => setActiveDownloadOption((e.target as HTMLInputElement).value)}
    name='download-options'
    renderAction={({ actionLabel }) => {
      const isSelected = activeDownloadOption === 'selected';
      const isDisabled = (isSelected && !selectedPreviews.length);
      const [selectedFileId] = selectedPreviews;
      const href =
        isSelected
          ? props.media_files.find(({ preview }) => preview === selectedFileId)?.download_link
          : props[activeDownloadOption as 'all']!.download_link;

      return <Button disabled={isDisabled} href={href} download>{actionLabel}</Button>;
    }}
    items={buildLabels({
      all: props.all?.count ?? 0,
      liked: props.liked?.count ?? 0,
      shared: props.shared?.count ?? 0
    })}
  />;

  return (<div className={s.root}>
    <div className={s.aside}>
      <Profile
        avatarUrl={props.logo}
        sessionName={buildSessionName(props.name)}
        downloadOptionsElem={downloadOptions}
      />
    </div>
    <div className={s.main}>
      <Gallery
        canSelect={canSelect}
        previews={props.media_files}
        selectedPreviews={selectedPreviews}
        toggleSelected={toggleSelected}
      />
    </div>
  </div>)
}