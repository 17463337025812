import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { PaidOpenRoomResponse } from 'api/types';
import { Button } from 'components/Button';
import { buildSessionName } from './hooks/common';
import { Gallery } from './Gallery';
import { Profile } from './Profile';

import s from './main-page.module.scss';


export type PaidMainPageProps = PaidOpenRoomResponse & { roomId: string; };

const VISITED_PAID_ROOMS_LS_KEY = 'VISITED_PAID';

const buildLsKey = (roomId: string) => `${VISITED_PAID_ROOMS_LS_KEY}.${roomId}`;

const setToLs = (roomId: string, val: boolean) => localStorage.setItem(buildLsKey(roomId), `${val}`);

const getFromLs = (roomId: string) => localStorage.getItem(buildLsKey(roomId)) ?? 'true';

export const PaidMainPage: FC<PaidMainPageProps> = (props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(
    getFromLs(props.roomId) === 'true'
  );

  useEffect(() => {
    setToLs(props.roomId, isPopupOpen);

    if (isPopupOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isPopupOpen]);

  return (<div className={s.root}>
    <div className={s.aside}>
      <Profile
        avatarUrl={props.logo}
        sessionName={buildSessionName(props.name)}
        downloadOptionsElem={<Button
          className={cn(s.control, s.controlDownload)}
          href={props.download_link}
          download
        >Download</Button>}
      />
    </div>
    <div className={s.main}>
      <Gallery
        canSelect={false}
        previews={props.media_files}
        selectedPreviews={[]}
        toggleSelected={() => {}}
      />
    </div>
    {isPopupOpen && <div className={s.overlay} onClick={() => setIsPopupOpen(false)}>
      <section className={s.popup} onClick={(e) => e.stopPropagation()}>
        <p className={s.popupTitle}>Purchase successful</p>
        <p className={s.popupText}>We've sent you an email confirming your purchase</p>
        <Button className={s.control} onClick={() => setIsPopupOpen(false)}>Ok</Button>
      </section>
    </div>}
  </div>)
}