import React, { FC, useState } from 'react';
import { config } from 'config';
import { ForPaymentOpenRoomResponse } from 'api/types';
import { Button } from 'components/Button';
import { RadioGroup, RadioItem } from 'components/RadioGroup';
import { buildSessionName, buildStatsDownloadOptionsBuilder, useCommonState } from './hooks/common';
import { Gallery } from './Gallery';
import { Profile } from './Profile';

import s from './main-page.module.scss';


export type ForPaymentMainPageProps = ForPaymentOpenRoomResponse & { roomId: string };

type LoadingStatus = 'idle' | 'pending' | 'fail' | 'completed';


export const ForPaymentMainPage: FC<ForPaymentMainPageProps> = (props) => {
  const [
    { selectedPreviews, canSelect, activeDownloadOption },
    { toggleSelected, setActiveDownloadOption }
  ] = useCommonState();
  const [loading, setLoading] = useState<LoadingStatus>('idle');
  const buildLabels = buildStatsDownloadOptionsBuilder(
    'Buy',
    'Redirect to payment page',
    props.price_photo > 0,
  );

  const downloadOptions = <RadioGroup
    value={activeDownloadOption}
    onChange={(e) => setActiveDownloadOption((e.target as HTMLInputElement).value)}
    name='download-options'
    renderAction={({value, actionLabel}: RadioItem) => {
      const isSelected = value === 'selected';
      const isDisabled = (isSelected && !selectedPreviews.length) || loading === 'pending'

      return <Button
        style={loading === 'fail' ? { backgroundColor: '#ad1a1a' } : undefined}
        onClick={() => {
          setLoading('pending');
          fetch(`${config.REACT_APP_API_BASE_URL}/public/payment/link`, {
            method: 'POST',
            body: JSON.stringify({
              room_slug: props.roomId,
              type: value,
              image_ids: selectedPreviews.length ? selectedPreviews : undefined
            }),
            headers: { 'Content-Type': 'application/json' },
          })
            .then((res) => res.json())
            .then((r) => {
              setLoading('idle');
              window.location.href = r.url;

            })
            .catch(() => setLoading('fail'));
        }}
        type='button'
        disabled={isDisabled}>
        {loading === 'fail' ? 'Opps, error... Repeat?' : actionLabel}
      </Button>
    }}
    items={
      buildLabels(
        props.price_album > 0
          ? {
            all: props.all?.count ?? 0,
            liked: props.liked?.count ?? 0,
            shared: props.shared?.count ?? 0
          }
          : {
            all: 0,
            shared: 0,
            liked: 0
          }
      )
    }
  />;

  return (<div className={s.root}>
    <div className={s.aside}>
      <Profile
        avatarUrl={props.logo}
        sessionName={buildSessionName(props.name)}
        downloadOptionsElem={downloadOptions}
      />
    </div>
    <div className={s.main}>
      <Gallery
        canSelect={canSelect}
        previews={props.media_files}
        selectedPreviews={selectedPreviews}
        toggleSelected={toggleSelected}
      />
    </div>
  </div>)
}
