import { FC, InputHTMLAttributes } from 'react';
import s from './radio.module.scss';

export type RadioProps = InputHTMLAttributes<HTMLInputElement>;

export const Radio: FC<RadioProps> = (attrs: InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <label className={s.wrapper}>
            <input {...attrs} className={s.radio} type="radio"/>
            <div className={s.thumb}/>
        </label>
    )
}