import { FC } from 'react';
import { CenterLayout } from 'modules/center-layout';

import s from './fail-page.module.scss';

export const FailPage: FC = () => {
  return <CenterLayout>
    <p className={s.text}>
      Something went wrong... Please,&nbsp;try&nbsp;again&nbsp;later.
    </p>
  </CenterLayout>;
}