import { ChangeEvent, FC, ReactNode } from 'react';
import { Radio } from 'components/Radio';
import { Output, OutputStub } from 'utils/for-components';

import type { RadioItem } from './types';
import s from './radio-group.module.scss';
import classNames from 'classnames';

export type RadioGroupProps = {
  items: RadioItem[];
  value: string | void;
  name: string;
  renderAction?: (item: RadioItem) => ReactNode;
  onChange?: Output<ChangeEvent>
}

export const RadioGroup: FC<RadioGroupProps> = ({
    items,
    renderAction,
    value,
    name,
    onChange= OutputStub}) => {
    return (<fieldset className={s.root}>
        {items.map((item) => {
            const active = value === item.value;
            return (<label key={item.value} className={classNames(s.item, {
                [s.itemActive]: active
            })}>
                <Radio name={name} value={item.value} checked={active} onChange={onChange}/>
                <div className={s.content}>
                    <div className={s.header}>
                        <span className={s.primaryInfo}>{ item.primaryInfo }</span>
                        <span className={s.secondaryInfo}>{ item.secondaryInfo }</span>
                    </div>
                    {active && renderAction && (<div className={`${s.body} fadeIn`}>
                        {renderAction(item)}
                        {item.additionalInfo && (<p className={s.additionalInfo}>{ item.additionalInfo }</p>)}
                    </div>)}
                </div>
            </label>)
        })}
    </fieldset>)

}