import { FC } from 'react';

import s from './loader.module.scss';
import cn from 'classnames';

export type LoaderProps = JSX.IntrinsicElements['div'] & {
  position?: 'default' | 'center';
};

export const Loader: FC<LoaderProps> = attrs => {
  return (
    <div
      {...attrs}
      className={cn(attrs.className, s.root, {
        [s.centered]: attrs.position === 'center'
      })}
    >
      <div className={s.side}></div>
      <div className={s.side}></div>
      <div className={s.side}></div>
      <div className={s.side}></div>
    </div>
  );
};
