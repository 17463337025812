import isObject from 'is-object';

type LinkAttrs = JSX.IntrinsicElements['a'];

type ButtonAttrs = JSX.IntrinsicElements['button'];

export const isLinkAttrs = (attrs: LinkAttrs | unknown): attrs is LinkAttrs => {
  return isObject(attrs) && 'href' in attrs;
};

export const isButtonAttrs = (attrs: ButtonAttrs | unknown): attrs is ButtonAttrs => {
  return isObject(attrs) && 'onClick' in attrs && Boolean(attrs.onClick);
};
