import { PossibleTypes, ButtonRoot } from './types';
import { isLinkAttrs } from 'utils/attrs-check';

export const detectTypeByAttrs = ({ elType, ...attrs }: Partial<ButtonRoot>): PossibleTypes => {
  if (elType) {
    return elType;
  }

  if (isLinkAttrs(attrs)) {
    return 'link';
  }

  return 'button';
};
