export interface ForPaymentPreview {
  _id: string;
  preview: string;
}

export interface FreePreview {
  download_link: string;
  preview: string
}

export type Preview = FreePreview | ForPaymentPreview;

export const isFreePreview = (arg: Preview): arg is FreePreview => !!(arg as FreePreview).download_link;

export const isForPaymentPreview = (arg: Preview): arg is ForPaymentPreview => !!(arg as ForPaymentPreview)._id;

export interface FreeGalleryGroupInfo {
  count: number;
  download_link: string;
}

export interface ForPaymentGalleryGroupInfo {
  count: number;
}

export type FreeOpenRoomResponse = {
  logo: string;
  name: string;
  gallery_type: 'free';
  all?: FreeGalleryGroupInfo;
  liked?: FreeGalleryGroupInfo;
  shared?: FreeGalleryGroupInfo;
  media_files: FreePreview[];
}

export type ForPaymentOpenRoomResponse = {
  logo: string;
  name: string;
  gallery_type: 'for_payment';
  all?: ForPaymentGalleryGroupInfo;
  liked?: ForPaymentGalleryGroupInfo;
  shared?: ForPaymentGalleryGroupInfo;
  media_files: ForPaymentPreview[];
  price_album: number;
  price_photo: number;
}

export type PaidOpenRoomResponse = {
  logo: string;
  name: string;
  download_link: string;
  gallery_type: 'paid';
  media_files: Preview[];
}

export type OpenRoomResponse = FreeOpenRoomResponse | ForPaymentOpenRoomResponse | PaidOpenRoomResponse;

export const isFreeRoom = (arg: OpenRoomResponse): arg is FreeOpenRoomResponse => arg.gallery_type === 'free';

export const isForPaymentRoom = (arg: OpenRoomResponse): arg is ForPaymentOpenRoomResponse => arg.gallery_type === 'for_payment';
