import { HTMLAttributes, ReactHTMLElement } from 'react';

export type Output<T> = (arg: T) => void;

export const OutputStub = () => {};

export type WithRootElProps<
    T = unknown,
    E extends ReactHTMLElement<HTMLElement> = ReactHTMLElement<HTMLElement>,
    A extends HTMLAttributes<E> = HTMLAttributes<E>
> = T & A;