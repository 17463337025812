import { useEffect, useState } from 'react';

type HookModel<T> = { pending: true; completed: false } | {
    pending: false;
    completed: true;
    failed?: boolean;
    success?: boolean;
    result?: T
};

const getInitialState = <T>(): HookModel<T> => ({
    pending: true,
    completed: false
});

export const useHttpState = <T>(...params: Parameters<typeof fetch>) => {
    const [state, setState] = useState(getInitialState<T>());
    useEffect(() => {
        fetch(...params)
            .then((r) => {
                if (!r.ok) {
                    r.text().then(console.warn);
                    throw new Error('Request Failed')
                }

                return r.json()
            })
            .then(result => {
                setState({
                    completed: true,
                    success: true,
                    failed: false,
                    pending: false,
                    result
                })
            })
            .catch(() => {
                setState({
                    completed: true,
                    failed: true,
                    success: false,
                    pending: false
                })
            })
    }, []);

    return state;
}