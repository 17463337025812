import { FC } from 'react';
import { CenterLayout } from 'modules/center-layout';

import s from './success-page.module.scss';

export const SuccessPage: FC = () => {
  return <CenterLayout>
    <p className={s.text}>
      You successfully paid for the album!
      The&nbsp;download&nbsp;link will be sent to&nbsp;your&nbsp;email.
    </p>
  </CenterLayout>;
}